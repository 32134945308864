import React from 'react'
import Page from '../components/Page'

const InterpretationPage = () => (
  <Page title="INTERPRETING FROM AND INTO CHINESE LANGUAGE">
    <p className="mt-8 w-2/3 text-center">
    Interpretation of our customers voice:<br/>
    <br/>
    We know, understand, and live Chinese law and the 36 Chinese Strategems.<br/>
    <br/>
    We interpret, assist, and lead our customer through Chinese business and contract negotations.<br/>
      </p>
  </Page>
)

export default InterpretationPage